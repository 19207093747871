<!-- <style>
	.keep-login-title{
		position: absolute;
		left: 0;
		top: 50%;
		padding-left: 26px;
		transform: translate(0, -50%);
		white-space: nowrap; 
	}

	.no-show{
		z-index: -1
	}
  
  .switch {
    position: relative;
    display: inline-block;
    width: 33px;
    height: 17px;
    margin-left: 10px;
    margin-top: 5px;
    margin-right: 10px;
  }

  .switch input { 
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .3s;
    transition: .23;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    top: 0px;
    left: 0px;
    /* bottom: 4px; */
    background-color: white;
    -webkit-transition: .3s;
    transition: .3s;
  }

  input:checked + .slider {
    background-color: #ff9800;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #ff9800;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(16px);
    -ms-transform: translateX(16px);
    transform: translateX(16px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 100%;
  }
</style> -->

<template>
    <section class="content">
        <div class="card card-primary row">
          <div class="col-12" v-if="width_screen <= 820 ">
            <form role="form" @submit.prevent="saveItem">
              <div class="card-body">
                <div class="form-group" v-for="(item, idx) in dataCustomCourier" :key="item.courier_id">
                  <div class="form-control h-100" :class="{ 'border-primary' : dataCustomCourier[idx].status }" style="border-width: 2px;">
                    <div class="icheck-material-orange d-flex flex-row-reverse justify-content-between align-items-center w-100">
                      <input type="checkbox" 
                        v-on:click="changeCourier($event)" 
                        :value="item.courier_id" 
                        :checked="item.status == '0' ? false : true" 
                        v-on:input="item.courier_id = $event.target.value" 
                        :id="item.prefix"
                      />
                      <label :for="item.prefix" class="form-check-label"></label>
                      <label :for="item.prefix" class="d-flex align-items-center w-100">{{ item.nama }}</label>
                      <div class="d-flex align-items-center justify-content-center" style="height: 35px;">
                        <img :src="getKurirLogo(item.prefix)" style="max-width: 100%;max-height: 60px;" />
                      </div>
                    </div>

                    <!-- <div class="form-control mb-3" style="height: fit-content;border-width: 1.5px; border-color: rgb(169, 169, 169); color: grey;" v-if="item.prefix == 'IDX' && item.flat_status == 'true'">
                      <div class="row" style="display: flex; align-items: center; padding: 5px;">
                        <div class="col-4 font-weight-bold">ID Express Flat</div>
                        <div class="col-5" style="text-align: right;">Klik untuk <span v-if="aktifkan_flat_idx">non</span>aktifkan</div>
                        <div class="col-2">
                          <label class="switch">
                            <input type="checkbox" id="flat_status" :checked="item.shiper_meta_flat" v-on:click="changeFlat($event)" :value="item.courier_id">
                            <span class="slider round"></span>
                          </label>
                        </div>
                      </div>
                      <div style="padding: 5px;">
                        <div class="font-weight-bold" style="border-top: 1px solid rgb(169, 169, 169); ;padding-top: 5px;"></div>
                        <div class="font-weight-bold" style="padding-top: 5px;">Info Pengiriman</div>
                        <ul class="mb-0" style="padding-inline-start: 20px;">
                          <li style="padding-top: 5px;">Berat diatas 500 gram (STD), diskon 20%</li>
                          <li style="padding-top: 5px;">Berat dibawah 500 gram</li>
                          <li style="padding-top: 5px;">Kirim dari jawa dan ke jawa dengan jangkauan 100% cuma <strong>Rp5.000/Kg</strong></li>
                        </ul>
                      </div>
                    </div> -->
                  </div>
                </div>
              </div>
                <div style="justify-content: center; text-align: center; align-items: center; padding-bottom: 25px;">
                  <button type="button" class="col-11 btn" style="background-color: #FF7203; color: white; padding: 10px;" data-dismiss="modal" v-on:click="saveCourier()"><strong>Simpan</strong></button>
                </div>
            </form>
          </div>
          <div class="col-6" v-else>
            <form role="form" @submit.prevent="saveItem">
              <div class="card-body">
                <div class="form-group" v-for="(item, idx) in dataCustomCourier" :key="item.courier_id">
                    <div class="form-control h-100" :class="{ 'border-primary' : dataCustomCourier[idx].status }" style="border-width: 2px;">
                      <div class="icheck-material-orange d-flex flex-row-reverse justify-content-between align-items-center w-100">
                        <input 
                          type="checkbox" 
                          :id="item.prefix"
                          v-on:click="changeCourier($event)" 
                          :value="item.courier_id" 
                          :checked="item.status == '0' ? false : true" 
                          v-on:input="item.courier_id = $event.target.value" 
                        />
                        <label :for="item.prefix" class="form-check-label"></label>
                        <label :for="item.prefix" class="d-flex align-items-center w-100">{{ item.nama }}</label>
                        <div class="d-flex align-items-center justify-content-center" style="height: 35px;">
                          <img :src="getKurirLogo(item.prefix)" style="max-width: 100%;max-height: 60px;" />
                        </div>
                      </div>

                      <!-- <div class="form-control mb-3" style="height: fit-content;border-width: 1.5px; border-color: rgb(169, 169, 169); color: grey;" v-if="item.prefix == 'IDX' && item.flat_status == 'true'">
                        <div class="row" style="display: flex; align-items: center; padding: 5px;">
                          <div class="col-4 font-weight-bold">ID Express Flat</div>
                          <div class="col-6" style="text-align: right;">Klik untuk <span v-if="aktifkan_flat_idx">non</span>aktifkan</div>
                          <div class="col-2">
                            <label class="switch">
                              <input type="checkbox" id="flat_status" :checked="item.shiper_meta_flat" v-on:click="changeFlat($event)" :value="item.courier_id">
                              <span class="slider round"></span>
                            </label>
                          </div>
                        </div>
                        <div style="padding: 5px;">
                          <div class="font-weight-bold" style="border-top: 1px solid rgb(169, 169, 169); ;padding-top: 5px;"></div>
                          <div class="font-weight-bold" style="padding-top: 5px;">Info Pengiriman</div>
                          <ul class="mb-0" style="padding-inline-start: 20px;">
                            <li style="padding-top: 5px;">Berat diatas 500 gram (STD), diskon 20%</li>
                            <li style="padding-top: 5px;">Berat dibawah 500 gram</li>
                            <li style="padding-top: 5px;">Kirim dari jawa dan ke jawa dengan jangkauan 100% cuma <strong>Rp5.000/Kg</strong></li>
                          </ul>
                        </div>
                      </div> -->
                    </div>
                </div>
              </div>
              <div style="justify-content: center; text-align: center; align-items: center; padding-bottom: 25px;">
                <button type="button" class="col-11 btn" style="background-color: #FF7203; color: white; padding: 10px;" data-dismiss="modal" v-on:click="saveCourier()"><strong>Simpan</strong></button>
              </div>
            </form>
          </div>
        </div>

        <div class="modal" tabindex="-1" role="dialog" ref="formDialogKurir" data-backdrop="static">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <form role="form" @submit.prevent="saveItem">
                <div class="modal-header">
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                  <div style="justify-content: center; align-items: center; text-align: center;">
                      <img :src="require(`../assets/img/ok.png`)" style="width: 100%"/>
                      <h4 class="modal-title" style="padding-top: 10px;">Berhasil merubah kustom kurir</h4>
                  </div>
                </div>
                <div style="justify-content: center; text-align: center; align-items: center; padding-bottom: 20px;">
                  <button type="button" class="col-11 btn" style="background-color: #FF7203; color: white; padding: 10px;" data-dismiss="modal"><strong>Kembali</strong></button>
                </div>
              </form>
            </div>
          </div>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import Vue from "vue";
import { authFetch, formatCurrency, charsRemover } from "@/libs/hxcore";
import Swal from "sweetalert2";
import $ from "jquery";
import ToggleButton from "vue-js-toggle-button";
import Warehouse from "@/dialog/Warehouse";
import vSelect from "vue-select";
import maskedInput from "vue-masked-input";
import { CurrencyInput } from "vue-currency-input";
import "dropzone/dist/dropzone.css";
import "vue-select/dist/vue-select.css";
import Daterangepicker from "daterangepicker";
import moment from "moment";
import VTooltip from 'v-tooltip';
import "icheck-material";
import "daterangepicker/daterangepicker.css";
import 'v-tooltip/dist/v-tooltip.css';

Vue.use(ToggleButton);
Vue.use(VTooltip, {
    themes: {
        'boscod': {
        $extend: 'tooltip',
        triggers: [
            'hover',
            'focus',
            'click',
            'touch',
        ],
        placement: 'auto',
        },
    },
    });

export default {
  data() {
    return {
      listCourier: [],
      method: "POST",
      dataCustomCourier: [],
      dataPayload: [],
      width_screen: window.innerWidth,
      aktifkan_flat_idx: false,
    };
  },
  created: function() {
    this.loadCourier();
    this.getDataCourier();
  },
  methods: {
    getKurirLogo(kurir) {
      const fileName = kurir.toLowerCase();
      return require(`../assets/img/${fileName}.png`); // the module request
      
    },
    getDataCourier() {
      let route = "/order/courier/courier_setting";
      try {
        authFetch(route).then(res => {
          res.json().then(json => {
            this.dataCustomCourier = json;

						for (var i = 0; i < json.length; i++){
							if (parseInt(json[i].courier_id) == 1){
								this.aktifkan_flat_idx = json[i].shiper_meta_flat
							}
							break;
						}
          });
        });
        
      } catch (error) {
        
      }
    },
    loadCourier() {
      let route = "/order/kurir";
      try {
        authFetch(route).then(res => {
          res.json().then(json => {
            if(json.success==true){
              this.listCourier = json.data;
              $('.loading-overlay').removeClass('show');
            }else{
              Swal.fire({
                title: "Oops...",
                icon: "error",
                text: json.message,
                showCloseButton: true
              });
            }
          });
        });
        
      } catch (error) {
        
      }
    },
    changeCourier: function(value)
    {
      var nilai_check = $('#'+value.target.id).prop("checked");
      // $('#'+value.target.id).prop("checked", nilai_check);

      // if (nilai_check == true){
      //   $('#'+value+'box').css({flex: '1 1 auto', width: '1%', border:'2px solid #FF7203', backgroundColor: '#FFE1C9'});
      // } else {
      //   $('#'+value+'box').css({flex: '1 1 auto', width: '1%', border:'2px solid #D3D3D3', backgroundColor: 'white'});
      // }

      // if (nilai_check == false && parseInt(value.target.value) == 1) {
			// 	$("#flat_status").prop("checked", false);
			// 	this.aktifkan_flat_idx = $("#flat_status").prop("checked", false);
			// }

      let checkData = this.dataPayload.find((element) => element.courier_id === value.target.value)
      if (checkData == undefined){
				// if (parseInt(value.target.value) == 1){
				// 	this.dataPayload.push({
				// 		"courier_id": value.target.value,
				// 		"status": nilai_check == true ? 1 : 0,
				// 		"status_flat": $("#flat_status").prop("checked"),
				// 	});
				// } else {
					this.dataPayload.push({
						"courier_id": value.target.value,
						"status": nilai_check == true ? 1 : 0
					});
				// }
			} else {
				if (parseInt(value.target.value) == 1){
					checkData.status = nilai_check == true ? 1 : 0;
					checkData.status_flat = $("#flat_status").prop("checked");
				} else {
					checkData.status = nilai_check == true ? 1 : 0;
				}
			}
    },
		// changeFlat: function(e)
		// {
		// 	if (e.target.checked == true && parseInt(e.target.value) == 1) {
		// 		$("#IDX").prop("checked", true);
    //     // $('#'+e.target.value+'checked').prop("checked", true);
		// 	}

		// 	this.aktifkan_flat_idx = e.target.checked;
		// 	let checkData = this.dataPayload.find((element) => element.courier_id === e.target.value);
		// 	if (checkData == undefined){
		// 		if (parseInt(e.target.value) == 1){
		// 			this.dataPayload.push({
		// 				"courier_id": e.target.value,
		// 				"status": $("#IDX").prop("checked") == true ? 1 : 0,
		// 				"status_flat": e.target.checked == true ? true : false,
		// 			});
		// 		} else {
		// 			this.dataPayload.push({
		// 				"courier_id": e.target.value,
		// 				"status": $("#IDX").prop("checked") == true ? 1 : 0
		// 			});
		// 		}
		// 	} else {
		// 		if (parseInt(e.target.value) == 1){
		// 			checkData.status = $("#IDX").prop("checked") == true ? 1 : 0;
		// 			checkData.status_flat = e.target.checked == true ? true : false;
		// 		} else {
		// 			checkData.status = $("#IDX").prop("checked") == true ? 1 : 0;
		// 		}
		// 	}
		// },
    saveCourier: async function()
    {
      const response = await authFetch("/order/courier/update_courier", {
          headers:{
              'Content-Type': 'application/json'
          },
          method: "POST",
          body: JSON.stringify(this.dataPayload),
      });
      let json = await response.json();
      console.log("json : ", json);
      if (json.succes == 'ok'){
        this.loadCourier();
        const e = this.$refs;
        this.showItemCustomCourier();
      } 
    },
    onResize() {
      this.width_screen = window.innerWidth
      // console.log("========+> ", window.innerWidth)
    },
    showItemCustomCourier: function() {
      const e = this.$refs;
      $(e.formDialogKurir).modal("show");
	  },
  },
  components: {
    vSelect,
    maskedInput,
    CurrencyInput,
    Warehouse,
  },
  mounted: function () {
    this.$nextTick((val) => {
      window.addEventListener('resize', this.onResize);
    })
  },
};
</script>

<style scoped>
.description-text{
	font-size: 15px;
}

.btn-circle {
	width: 15px;
	height: 15px;
	padding: 0px 0px;
	border-radius: 10px;
	text-align: center;
	font-size: 10px;
	line-height: 1.42857;
}

@media (max-width: 575.98px) {
	.position-sticky {
		position: static !important;
	}
}
</style>